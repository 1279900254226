import React from "react";
import { Link } from "react-router-dom";

/**
 * Componente para mostrar la navegación del sitio
 *
 * @component
 */

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid padding-fluid">
        <div className="navbar-header">
          <button
            id="btnMenu"
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link to="/home" aria-label="home">
            <img src="/img/focus.png" style={{ paddingTop: "2px" }} className="img-responsive logo" alt="ram" />{" "}
          </Link>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              {/* <Link to="/home">Home</Link> */}
              <a href="#header" className="">Home</a>
            </li>
            <li>
              {/* <Link to="#wyus">¿Por qué Focus?</Link> */}
              <a href="#wyus" className="">¿Por qué Focus?</a>
            </li>
            <li>
              {/* <Link to="/home">Soluciones</Link> */}
              <a href="#services" className="">Soluciones</a>
            </li>
            {/* <li>
              <Link to="/news">News</Link>
              <a href="#news" className="page-scroll">&nbsp;</a>
            </li> */}
            {/* <li>
              <Link to="/nuestrotrabajo">Nuestro Trabajo</Link>
              <a href="#briefcase" className="page-scroll">&nbsp;</a>
            </li> */}
            {/* <li>
              <Link to="/equipo">Equipo</Link>
              <a href="#team" className="page-scroll">&nbsp;</a>
            </li> */}
            <li>
              {/* <Link to="/home">Clientes</Link> */}
              <a href="#clients" className="">Clientes</a>
            </li>
            <li>
              <a href="#contactanos">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
