/**
 * Componente para colocar el scroll del navegador al inicio de la pantalla cada vez que se cambia entre pantallas
 *
 * @component
 */

export const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    window.scroll(0, 0);
  return null;
}