import React, { useState, useEffect } from "react";
import { Team } from "./components/team";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import { Clients } from "./components/clients";
import { Header } from "./components/header";
import { Us } from "./components/us";
import { Carousel2 } from "./components/carousel";
import { Services } from "./components/services";
import CookieConsent from "react-cookie-consent";
import { ScrollToTop } from "./components/scrollToTop";
import { CookiesPolicy } from "./components/cookiesPolicy";
import { AnalyticNews } from "./components/analyticNews";

import JsonData from "./data/data.json";
import { Navigate, Route, Routes } from "react-router-dom";
import { positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import SmoothScroll from "smooth-scroll";
import "./App.css";
import $ from 'jquery';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: function (anchor, toggle) {
    if ($(toggle).attr('href') === '#wyus') {
        return 50;
    } else if ($(toggle).attr('href') === '#services') {
        return 10;
    } else if ($(toggle).attr('href') === '#clients') {
      return 80;
    }
    else
      return 50;
  },
});

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

let enter = false;

/**
 * Component inicial donde se configura las rutas de cada una de las opciones
 *
 * @component
 */

const App = () => {
  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      $('#btnMenu').not('.collapsed').trigger( "click" );
    }
  };

  const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
        document.documentElement.lang = 'es';

        if (typeof window !== 'undefined') {
          window.addEventListener('scroll', controlNavbar);
    
          // cleanup function
          return () => {
            window.removeEventListener('scroll', controlNavbar);
          };
        }

    }, []);

    $(function() {
      if($('#rcc-confirm-button') !== undefined && !enter) {
          enter = true;
          $('#rcc-confirm-button').parent().css("text-align","center");
          $('#rcc-confirm-button').parent().addClass('text-center-cookies');
          $('<br>').insertAfter('#rcc-confirm-button');
      }
    });

  return (
    <div>
      <Routes>
        <Route path='/' element={
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Us data={landingPageData.Us} />
            <Carousel2 data={landingPageData.Carousel} />
            <Services data={landingPageData.Services} />
            <Team data={landingPageData.Team} />
            <Clients data={landingPageData.Clients} />
            {/* <News data={landingPageData.News} /> */}
            <AlertProvider template={AlertTemplate} {...options}>
                <Contact data={landingPageData.Contact} dataNews={landingPageData.News} dataServices={landingPageData.Services} />
            </AlertProvider>
            <CookieConsent
                location="bottom"
                buttonText="Aceptar todas las cookies"
                declineButtonText="Rechazarlas todas"
                enableDeclineButton={true}
                flipButtons={true}
                // debug={true}
                cookieName="myAwesomeCookieName2"
                style={{ background: "#F1D831", color: "#000000" }}
                buttonStyle={{ background: "#333333", color: "#FFFFFF", fontSize: "11px", padding: "10px", margin: '10px 15px 0px 15px', width: '190px' }}
                declineButtonStyle={{ background: "#E2E2E2", color: "#000000", fontSize: "11px", padding: "10px", margin: '10px 15px 15px 15px', width: '190px' }}
                expires={150}
                contentStyle={{ display: "grid" }}
                >
                <p style={{ textAlign: "justify", 
                            paddingLeft: "40px", 
                            paddingRight: "40px", 
                            paddingTop: "20px", 
                            paddingBottom: "20px",
                            color: "black",
                            fontSize: "11px" }}>
                                Estimado visitante, le informamos que este sitio utiliza mecanismos de seguimiento de sitios web (denominados cookies) para poder operar de manera adecuada, adicionalmente también utilizamos cookies por parte de terceros para mejorar continuamente nuestros servicios y para mostrar anuncios de acuerdo con los intereses de los usuarios. Al aceptar este mensaje, estoy aceptando el uso de cookies por parte de esta web. <a className="linkPoliticas" href="/CookiesPolicy" target="_blank" rel="noopener noreferrer">Política de Cookies</a> <a className="linkPoliticas" href="/politica-de-privacidad"  target="_blank" rel="noopener noreferrer">Protección de Datos</a>
                </p>
            </CookieConsent>
        </div>
        } />
        <Route path='/CookiesPolicy' element={
          <div>
            <ScrollToTop />
            <CookiesPolicy />
          </div>
        } />
        <Route path='/politica-de-privacidad' element={
          <div>
            <ScrollToTop />
            <AnalyticNews />
          </div>
        } />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
};

export default App;
