import React from "react";
// import { Link } from "react-router-dom";

/**
 * Componente para mostrar los servicios, estas se cargan a través de iteraciones tomando la 
 * información desde el archivo .src/data/data.json -> "Services"
 *
 * @component
 * @example
 * 
 * "Services": [
    {
      "id": 0,
      "img": "/img/services/BuyerPersona.png",
      "img_mob": "/img/services/BuyerPersona_mob.png",
      "imgFooter": "/img/services/BuyerPersona.png",
      "name": "Buyer Persona",
      "text": "Construimos perfiles de audiencia detallados para impulsar estrategias de marketing personalizadas.",
      "url": "/servicios/buyerPersona"
    }
  ]
 * 
 */

export const Clients = (props) => {

  if(props.data)
  {
    return (
      <div id="clients">
        <div className="container-fluid padding-fluid">
          <div className="section-title">
            <h2>Clientes</h2>
          </div>
          <div className="row text-center">
          <div className="col-md-12">Clientes Ecuador</div>
          <div className="col-md-12">
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="rey queso" className="img-responsive" src="img/clients/image1.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="deuna" className="img-responsive" src="img/clients/image2.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="uide" className="img-responsive" src="img/clients/image3.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="diners club" className="img-responsive" src="img/clients/image4.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="titanium" className="img-responsive" src="img/clients/image5.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="titanium" className="img-responsive" src="img/clients/image6.png"></img>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image10.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image7.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image8.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image9.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image11.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="guitig" className="img-responsive" src="img/clients/image12.png"></img>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image13.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image14.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image15.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image16.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image35.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="" className="img-responsive" src="img/clients/image17.png"></img>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="maresa" className="img-responsive" src="img/clients/image18.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="Tesalia" className="img-responsive" src="img/clients/image19.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="graiman" className="img-responsive" src="img/clients/image20.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="fybeca" className="img-responsive" src="img/clients/image21.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="condado" className="img-responsive" src="img/clients/image32.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="multicines" className="img-responsive" src="img/clients/image33.png"></img>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-1 hidden-xs imagen"></div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="sanasana" className="img-responsive" src="img/clients/image34.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="adelca" className="img-responsive" src="img/clients/image23.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="teleamazonas" className="img-responsive" src="img/clients/image24.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="teleamazonas" className="img-responsive" src="img/clients/image25.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="teleamazonas" className="img-responsive" src="img/clients/image26.png"></img>
            </div>
            <div className="col-md-1 hidden-xs imagen"></div>
          </div>
          <div className="col-md-12 col-xs-12">&nbsp;</div>
          <div className="col-md-12 col-xs-12">&nbsp;</div>
          <div className="col-md-12 col-xs-12">Clientes Perú</div>
          <div className="col-md-12 col-xs-12">
            <div className="col-md-2 hidden-xs imagen"></div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="pycca" className="img-responsive" src="img/clients/image28.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="manicho" className="img-responsive" src="img/clients/image29.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="grupo farma" className="img-responsive" src="img/clients/image30.png"></img>
            </div>
            <div className="col-md-2 col-xs-4 imagen">
              <img alt="grupo farma" className="img-responsive" src="img/clients/image31.png"></img>
            </div>
            <div className="col-md-2 hidden-xs imagen"></div>
          </div>
          <div className="col-md-12">&nbsp;</div>
          <div className="col-md-12">&nbsp;</div>
        </div>
        </div>
      </div>
    );
  }
  else
    return (<div></div>);
};
