const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("x-api-key", process.env.REACT_APP_API_TOKEN);

const serviceUrl = process.env.REACT_APP_API_URL

export async function saveUserData(data) {
    try {
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        };
    
        const response = await fetch(serviceUrl, requestOptions);
        return response;
    } catch (error) {
        return null
    }
    
}