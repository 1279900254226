import React from "react";


/**
 * Componente para mostrar la política de cookies (Contenido estático)
 *
 * @component
 */

export const CookiesPolicy = (props) => {
    return (
        <div id="cookiesPolicy">
            <div className="container-fluid padding-fluid">
            <div className="row">
                    <div className="col-md-12 text-center">
                        <img alt="" src="img/focus2.png"></img>
                    </div>
                </div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>

                <h3 className="title">POLÍTICA DE COOKIES</h3>
                <br></br>
                <br></br>
                <p><h3>DECLARACIÓN DE POLÍTICA DE COOKIES</h3><br></br>
                    Una cookie es un pequeño archivo que se descarga en el equipo del usuario (computadora, laptop, smartphone, tablet, etc.) cuando accede a determinadas páginas web, este archivo almacena y recupera información sobre la navegación que se efectúa desde dicho equipo.
                    <br></br><br></br>
                    Si tiene dudas sobre el uso de cookies por parte de INVESTIGACIÓN DE MERCADO FOCUSMARKET S.A., puede contactar a delegadodatos_focus@focusresearch.com.ec.
                    <br></br><br></br>
                    <h3>TIPOS DE COOKIES UTILIZADAS Y FINALIDADES</h3><br></br>
                    Los tipos de cookies que utilizamos están categorizados como:
                    </p>
                        <ul>
                            <li>Estrictamente necesarias: son aquellas necesarias para la operación del sitio web, garantizan el correcto funcionamiento del mismo y la utilización de las diferentes opciones o servicios que lo componen. Son cookies que permiten, por ejemplo, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra, gestionar el pago, almacenar contenidos para la difusión de vídeos o sonido, habilitar contenidos dinámicos (por ejemplo, animación de carga de un texto o imagen) o compartir contenidos a través de redes sociales.</li>
                            <li>Si se desactivan estas cookies, no podrá obtener la funcionabilidad del sitio web total o parcialmente.</li>
                            <li>Complementarias: son aquéllas que almacenan información no necesaria para el funcionamiento del sitio web pero que permiten habilitar funcionalidades adicionales. Con ello se posibilita la gestión, de la forma más eficaz posible, de los espacios publicitarios y el contenido del sitio web según el perfil específico del usuario.</li>
                        </ul>
                    <p>
                    <br></br>
                    En particular, se utilizan cookies complementarias con la finalidad de:<br></br>
                    </p>
                        <ul>
                            <li>Mejorar el Rendimiento: Cookies para recoger información anónima sobre el uso de la página web. Esta información ayuda a mejorar nuestra web y nos ayuda a entender la efectividad de esta. En algunos casos, utilizamos servicios de terceras partes de confianza como Google Analytics y Hotjar para recoger dicha información, pero solo la utilizan para los fines previamente descritos.</li>
                            <li>Funcionalidad: Cookies se utilizan para proveer servicios o recordar ajustes para mejorar su experiencia, por ejemplo, utilizar su dirección IP para identificar accesos no autorizados al sistema.</li>
                            <li>Focalización y publicidad: Estas cookies son utilizadas por terceras partes de confianza para publicitar anuncios que se adapten a sus intereses. También se utilizan para limitar el número de veces que ve un anuncio y para medir la efectividad de las campañas publicitarias. La información contenida en dichas cookies es anónima y no contiene información de carácter personal.</li>
                        </ul>
                    <p>
                    <br></br><br></br>
                    <h3>ADMINISTRACIÓN DE COOKIES</h3><br></br>
                    Puede desactivar las cookies especificas sin que afecte al funcionamiento del sitio web (cookies complementarias); sin embargo, la información captada por estas cookies sobre el uso de nuestra web puede afectar el funcionamiento adicional.<br></br>
                    El usuario podrá elegir qué cookies quiere que funcionen en este sitio web mediante:
                    <br></br><br></br>
                    La configuración del navegador; por ejemplo:<br></br>
                    </p>
                        <ul>
                            <li>Chrome, desde <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                            <li>Explorer, desde <a target="_blank" rel="noopener noreferrer" href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                            <li>Firefox, desde <a target="_blank" rel="noopener noreferrer" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es</a></li>
                        </ul>
                    <p>
                    <br></br>
                    Asimismo, el usuario puede activar en su navegador web:<br></br>
                    </p>
                        <ul>
                            <li>La navegación privada, mediante la cual su navegador deja de guardar el historial de navegación, contraseñas de sitios web, cookies y otra información de las páginas que visita.</li>
                            <li>La función de no rastrear, por la que el navegador pide a los sitios web que visita que no rastreen sus hábitos de navegación para, por ejemplo, servirle publicidad de su interés en los sitios que visita.</li>
                        </ul>
            </div>
            <div style={{ height: "70px", marginTop: "150px", backgroundColor: "#F2D747" }}>
      
            </div>
        </div>
    );
};
