import React from "react";
// import { Carousel } from '@trendyol-js/react-carousel';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

/**
 * Componente para mostrar el corusel, estas se cargan a través de iteraciones tomando la 
 * información de las carousel desde el archivo .src/data/data.json -> "Carousel"
 *
 * @component
 * @example
 * 
 * "Carousel": [
    {
      "img": "img/01.webp",
    }
  ]
 * 
 */

const animation = { duration: 8000, easing: (t) => t }

export const Carousel2 = (props) => {

  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: "precision",
    slides: {
      perView: 3
    },
    drag: false,
    created(s) {
      s.moveToIdx(1, true, animation)
      s.container.addEventListener("mouseover", () => {
        s.animator.stop();
      })
      s.container.addEventListener("mouseout", () => {
        // console.log(s.track.details.abs)
        s.moveToIdx(s.track.details.abs + 1, true, animation)
        //s.next();
      })
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation)
    },
  })

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 620;

  // React.useEffect(() => {
  //   const saveWidth = () => {
  //     setWidth(window.innerWidth)
  //   };
  //   /* Inside of a "useEffect" hook add an event listener that updates
  //      the "width" state variable when the window size changes */
  //   window.addEventListener("resize", () => saveWidth);

  //   /* passing an empty array as the dependencies of the effect will cause this
  //      effect to only run when the component mounts, and not each time it updates.
  //      We only want the listener to be added once */
  //      return () => window.removeEventListener("resize", saveWidth);
  // }, []);


  

  if(props.data)
  {
    return (
      <div id="carousel">
        <div>
          <div>

          <div ref={sliderRef} className="keen-slider">
            {props.data.map((d, i) => (
              <div key={`dv0${i}`} className="thumbnail keen-slider__slide">
                  <div key={`dv1${i}`} className="gradient" style={{ width: "380px", height: "400px"}}>
                    <img key={`img${i}`} alt="" className="img-responsive" src={d.img}></img>
                    <span key={`span${i}`} className="caption">
                      <h4 key={`h4${i}`}>{d.title}</h4>
                    </span>
                    <span className="text">
                      <span dangerouslySetInnerHTML={{__html:d.text}} key={`p${i}`}></span>
                    </span>
                  </div>
              </div>
            ))}
          </div>

            {/* <div id="dvCarousel" style={{display: 'none'}}>
              <Carousel 
              show={width < breakpoint ? 1.2 : 3.7} 
              slide={1} 
              swipeOn={0.2}
              transition={0.2} 
              swiping={true}
              responsive={true} 
              infinite={true}
              rightArrow=""
              leftArrow="">
                {props.data.map((d, i) => (

                    <div key={`dv0${i}`} className="thumbnail">
                        <div key={`dv1${i}`} className="gradient" style={{ width: "380px", height: "400px"}}>
                          <img key={`img${i}`} alt="" className="img-responsive" src={d.img}></img>
                          <span key={`span${i}`} className="caption">
                            <h4 key={`h4${i}`}>{d.title}</h4>
                          </span>
                          <span className="text">
                            <span dangerouslySetInnerHTML={{__html:d.text}} key={`p${i}`}></span>
                          </span>
                        </div>
                    </div>
                ))}
              </Carousel>
            </div> */}
          </div>
        </div>
      </div>
      
    );
  }
  else
    return (<div></div>);
};
