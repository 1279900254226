import React from "react";
// import { Link } from "react-router-dom";

/**
 * Componente para mostrar los servicios, estas se cargan a través de iteraciones tomando la 
 * información desde el archivo .src/data/data.json -> "Services"
 *
 * @component
 * @example
 * 
 * "Services": [
    {
      "id": 0,
      "img": "/img/services/BuyerPersona.webp",
      "img_mob": "/img/services/BuyerPersona_mob.webp",
      "imgFooter": "/img/services/BuyerPersona.webp",
      "name": "Buyer Persona",
      "text": "Construimos perfiles de audiencia detallados para impulsar estrategias de marketing personalizadas.",
      "url": "/servicios/buyerPersona"
    }
  ]
 * 
 */

export const Services = (props) => {

  if(props.data)
  {
    return (
      <div id="services">
        <div className="container-fluid padding-fluid">
          <div className="section-title">
            <h2>Soluciones y áreas de expertise:</h2>
            <h4 className="subTitleServices">Cualitativo y cuantitativo</h4>
          </div>
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`dv1${i}`} className="col-xs-12 col-md-3" style={{ paddingBottom: "5px" }}>
                    <div className="thumbnail">
                        <div className="gradient">
                          <div className="icon">
                            <div style={{ backgroundColor: "#F1D831", width: "45px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px" }}>
                              {/* <i className={d.img} style={{ fontSize: "20px" }}></i> */}
                              <img alt="" src={d.img} style={{ height: "45px", width: "45px" }}></img>
                            </div>
                          </div>
                          <span className="caption">
                            <h4>{d.name}</h4>
                          </span>
                          <span className="text">
                            <span dangerouslySetInnerHTML={{__html:d.text}} key={`p${i}`}></span>
                          </span>
                        </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
  else
    return (<div></div>);
};
