import React from "react";

/**
 * Componente para mostrar las News, estas se cargan a través de iteraciones tomando la 
 * información de las news desde el archivo .src/data/data.json -> "News"
 *
 * @component
 * @example
 * 
 * "News": [
    {
      "img": "img/news/01.webp",
      "imgHome": "img/news/01_home.webp",
      "text": "<b>Data Driven Marketing: personalizado, relevante y efectivo</b>",
      "shortText": "Data Driven Marketing",
      "new": true,
      "url": "/news/data-driven-marketing"
    }
  ]
 * 
 */

export const Team = (props) => {

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 620;

  // React.useEffect(() => {
  //   const saveWidth = () => {
  //     setWidth(window.innerWidth)
  //   };
  //   /* Inside of a "useEffect" hook add an event listener that updates
  //      the "width" state variable when the window size changes */
  //   window.addEventListener("resize", () => saveWidth);

  //   /* passing an empty array as the dependencies of the effect will cause this
  //      effect to only run when the component mounts, and not each time it updates.
  //      We only want the listener to be added once */
  //      return () => window.removeEventListener("resize", saveWidth);
  // }, []);

    return (
      <div id="team">
        <div className="container-fluid padding-fluid">
          <div className="section-title">
            <h2>Equipo</h2>
          </div>
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`dv1${i}`} className="col-xs-12 col-md-4" style={{ paddingBottom: "5px" }}>
                    <div className="thumbnail">
                        <div className="gradient">
                          <div className="img-team">
                            {/* <i className={d.img} style={{ fontSize: "20px" }}></i> */}
                            <img key={`img${i}`} alt="" src={d.img} className="img-responsive"></img>
                          </div>
                          <span className="text">
                            <span dangerouslySetInnerHTML={{__html:d.text}} key={`p${i}`}></span>
                          </span>
                        </div>
                    </div>
                    <div className="caption">
                      {d.name + ' - ' + d.job}
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
      
    );
};
