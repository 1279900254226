import React from "react";

/**
 * Componente para mostrar el banner del sitio, aquí se configura el video del banner
 *
 * @component
 */

export const Header = (props) => {

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  React.useEffect(() => {
      /* Inside of a "useEffect" hook add an event listener that updates
      the "width" state variable when the window size changes */
      window.addEventListener("resize", () => setWidth(window.innerWidth));

      /* passing an empty array as the dependencies of the effect will cause this
      effect to only run when the component mounts, and not each time it updates.
      We only want the listener to be added once */
  }, []);

  return (
    <header id="header">
      <div className="intro">
      <video src={width < breakpoint ? "/video/Banner_mob.mp4" : "/video/Banner.mp4"} playsInline autoPlay loop muted style={{ width: '100%'}}>
        
        </video>
        
      </div>
    </header>
  );
};
