import React from "react";

/**
 * Componente para mostrar la política de analítica (Contenido estático)
 *
 * @component
 */

export const AnalyticNews = (props) => {
    return (
        <div id="analyticNews">
            <div className="container-fluid padding-fluid">

                <div className="row">
                    <div className="col-md-12 text-center">
                        <img alt="" src="img/focus2.png"></img>
                    </div>
                </div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <h3 className="title">POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES</h3>
                <br></br>
                <br></br>
                <p>INVESTIGACIÓN DE MERCADO FOCUSMARKET S.A. reconoce la importancia de la privacidad y protección de datos personales de los titulares y se compromete a cumplir con los estándares de protección de datos personales requeridos. Por lo tanto, ha adoptado medidas de confidencialidad y seguridad necesarias para la información que le es proporcionada y/o a la que tiene acceso, en cumplimiento con lo prescrito en la Ley Orgánica de Protección de Datos Personales.
                    <br></br><br></br>
                    <p>El contenido de este documento es estrictamente informativo.</p>
                    <br></br>
                    <b><h3>OBJETIVO:</h3></b><br></br>
                    La presente política tiene como objetivo el informar las condiciones bajo las cuales son protegidos y tratados los datos personales de los clientes y/o usuarios de la Compañía.
                    <br></br><br></br>
                    <b><h3>ALCANCE:</h3></b><br></br>
                </p>
                <ul>
                    <li>La presente política se aplica a todos los canales de comunicación, procesos y actividades realizadas por FOCUS de forma manual o electrónica y que incluyan o estén relacionados con datos personales.</li>
                    <li>Para efectos de la Ley Orgánica de Protección de Datos Personales, la presente política se aplica a todos los tratamientos de datos personales realizados por FOCUS.</li>
                </ul>
                <p>
                <br></br>
                <b><h3>GLOSARIO DE TÉRMINOS:</h3></b><br></br>
                Dato personal. <br></br>
                Dato que identifica o hace identificable a una persona natural, directa o indirectamente.<br></br>
                <br></br>
                Titular <br></br>
                Persona natural cuyos datos son objeto de tratamiento.<br></br>
                <br></br>
                Responsable del tratamiento de datos personales <br></br>
                Persona natural o jurídica, pública o privada, autoridad pública u otro organismo que solo o conjuntamente con otros decide sobre la finalidad y el tratamiento de datos personales.<br></br>
                <br></br>
                Encargado del tratamiento <br></br>
                Persona natural o jurídica, pública o privada, autoridad pública u otro organismo que solo o conjuntamente con otros trate datos personales a nombre y por cuenta de un responsable de un tratamiento de datos personales.<br></br>
                <br></br>
                Tratamiento de datos personales<br></br>
                Cualquier operación o conjunto de operaciones realizadas sobre datos personales, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizado, tales como: la recogida, recopilación, obtención, registro, organización, estructuración, conservación, custodia, adaptación, modificación, eliminación, indexación, extracción, consulta, elaboración, utilización, posesión, aprovechamiento, distribución, cesión, comunicación o transferencia, o cualquier otra forma de habilitación de acceso, cotejo, interconexión, limitación, supresión, destrucción y, en general, cualquier uso de datos personales.
                <br></br><br></br>
                <b><h3>FINALIDADES DE TRATAMIENTO:</h3></b>
                </p>
                <ul>
                    <li>Cumplimiento de las condiciones necesarias para la prestación efectiva del producto o servicio contratado.</li>
                    <li>Tramitación de requerimientos y/o reclamos.</li>
                    <li>Registro, sustento y control de las operaciones derivadas de la atención al cliente.</li>
                    <li>Cumplimiento de obligaciones legales y regulatorias.</li>
                    <li>Analizar y descubrir necesidades de clientes.</li>
                    <li>Realizar análisis y mejora de productos y servicios.</li>
                    <li>Realizar estudios de mercado.</li>
                    <li>Efectuar análisis de satisfacción del cliente e investigaciones estadísticas.</li>
                    <li>Desarrollar acciones comerciales de productos y servicios.</li>
                    <li>Comercializar y promocionar diferentes productos y servicios (incluyendo el envío de publicidad por cualquier medio).</li>
                    <li>Cumplimiento de obligaciones contractuales.</li>
                </ul>
                <p>
                <br></br>
                Finalidad de tratamiento<br></br>
                Videovigilancia<br></br>
                Descripción de la finalidad de tratamiento<br></br>
                La videovigilancia es utilizada como medio de prueba ante algún incidente relacionado con seguridad patrimonial, almacenado por un período de tiempo de 90 días, a través del sistema NVR<br></br>
                Datos personales recopilados<br></br>
                IMAGEN - VIDEO<br></br>
                <br></br>
                <b><h3>TIEMPO DE CONSERVACIÓN:</h3></b><br></br>
                Datos personales serán conservados durante un tiempo no mayor al necesario para cumplir con la finalidad de su tratamiento.<br></br><br></br>
                Para garantizar que los Datos personales no se conserven más tiempo del necesario, el responsable del tratamiento de datos personales realizará una revisión de su Base de datos cada cinco años.<br></br><br></br>
                Los Datos personales pueden ser conservados para su tratamiento por un tiempo mayor al establecido anteriormente para fines de investigación estadística, para lo cual se implementarán garantías de seguridad y protección de los Datos personales.
                <br></br><br></br>
                <b><h3>BASE LEGAL PARA EL TRATAMIENTO:</h3></b><br></br>
                Todo tratamiento de datos personales se realiza en estricto cumplimiento de acuerdo con el artículo 7 y 8 de la Ley Orgánica de Protección de Datos Personales con base en:<br></br>
                </p>
                <ul>
                    <li>Consentimiento del titular para el tratamiento de sus datos personales, para una o varias finalidades específicas.<br></br></li>
                    <li>El tratamiento es necesario para la ejecución de medidas precontractuales a petición del titular o para el cumplimiento de obligaciones contractuales perseguidas por el responsable del tratamiento de datos personales, encargado del tratamiento de datos personales o por un tercero legalmente habilitado.</li>
                </ul>
                <br></br>
                EFECTO DE SUMINISTRAR DATOS PERSONALES ERRÓNEOS O INEXACTOS<br></br><br></br>
                La negativa de suministrar datos o proporcionar datos inexactos, pueden ocasionar que la finalidad para la cual son recopilados no pueda cumplirse total o parcialmente.
                <br></br><br></br>
                Por lo cual, FOCUS no será responsable de los resultados derivados del procesamiento de información que se realicen como consecuencia de datos erróneos, desactualizados o inexactos. FOCUS se reserva el derecho a no prestar los servicios correspondientes en caso de que se proporcione datos erróneos, desactualizados o inexactos.
                <br></br><br></br>
                TRANSFERENCIAS O COMUNICACIONES DE DATOS PERSONALES<br></br><br></br>
                Para cumplir con las finalidades previamente descritas, los datos personales podrán consultarse, compartirse, transferirse y/o comunicarse a sus proveedores, subsidiarias, sociedades afiliadas, relacionadas y/o auxiliares, empresas encargadas del tratamiento de datos y/o sociedades con las que la Compañía mantenga relación a nivel nacional o internacional. Para el efecto, FOCUS garantiza que se implementarán medidas técnicas y organizativas adecuadas para proteger los datos personales.
                <br></br><br></br>
                EMPRESA COMO RESPONSABLE DE TRATAMIENTO DE DATOS PERSONALES<br></br>
                Razón social<br></br>
                FOCUS<br></br>
                Dirección del domicilio legal<br></br>
                Guipuzcoa E14-46 y Mallorca, Edificio QPH,<br></br>
                Quito - Ecuador<br></br>
                Número de teléfono<br></br>
                023956060<br></br>
                Correo electrónico<br></br>
                delegadodatos_focus@focusresearch.com.ec
                <br></br><br></br>
                <b><h3>DERECHOS DE LOS TITULARES</h3></b><br></br>
                Se reconoce a los titulares de datos personales el Acceso y Rectificación al tratamiento que se realice sobre la información de sus datos personales.<br></br><br></br>
                Para hacer ejercicio de sus derechos, el representante legal o titular de los datos personales podrá acceder, rectificar, actualizar, eliminar, oponerse y/o suspender el uso de sus datos personales en cualquier momento, de acuerdo con la Ley y normativa aplicables.<br></br>
                Para ejercer estos derechos, podrá contactar a la Compañía a través de los medios habilitados para tal fin.<br></br>
                Los interesados podrán enviar la solicitud a través de un corrreo a FOCUS con la Ref. “Protección de datos” de manera física o electrónica.<br></br><br></br>
                Dirección social<br></br>
                Guipuzcoa E14-46 y Mallorca, Edificio QPH,<br></br>
                Quito - Ecuador<br></br>
                Correo electrónico<br></br>
                delegadodatos_focus@focusresearch.com.ec<br></br>
                Horario de atención en oficina<br></br>
                9:00 am a 5:00 pm<br></br><br></br>
                Tome en consideración que será necesario que acredite su identidad como titular o representante legal del titular para poder realizar el trámite.<br></br><br></br>
                FOCUS se compromete a atender dicha solicitud en un plazo razonable de acuerdo con la Ley y normativa aplicables.
                <br></br><br></br>
                <h3>PROCEDIMIENTO PARA REVOCAR EL CONSENTIMIENTO</h3><br></br>
                El titular o su representante legal puede solicitar la revocación de su consentimiento a un tratamiento de datos personales en cualquier momento y de forma gratuita.<br></br><br></br>
                Para lo cual deberá enviar la solicitud o contactarse a través de los siguientes medios.<br></br><br></br>
                Dirección social<br></br>
                Guipuzcoa E14-46 y Mallorca, Edificio QPH,<br></br>
                Quito - Ecuador<br></br>
                Correo electrónico<br></br>
                delegadodatos_focus@focusresearch.com.ec<br></br>
                Horario de atención en oficina<br></br>
                9:00 am a 5:00 pm<br></br><br></br>
                Tome en consideración que será necesario que acredite su identidad como titular o representante legal del titular para poder realizar el trámite.<br></br><br></br>
                El delegado de protección de datos de FOCUS se comunicará con usted para informar el resultado de su solicitud o de ser el caso solicitar información adicional que permita validar su identidad o clarificar el tratamiento sobre el cual realiza la solicitud.
                <br></br><br></br>
                <h3>MEDIDAS DE SEGURIDAD</h3><br></br>
                Para proteger los datos personales bajo su responsabilidad, FOCUS ha implementado un sistema de gestión de protección de datos personales alineado con el estándar internacional ISO/IEC 27701:2019 con base en una adecuada gestión de riesgos, adoptando las medidas de control a fin de mantener los datos personales en un nivel de protección aceptable que evite de forma razonable cualquier consecuencia negativa a los titulares de datos personales.
                <br></br><br></br>
                <h3>ENCARGATURA DE TRATAMIENTO</h3><br></br>
                Cuando FOCUS realice acuerdos para encargar el tratamiento de datos personales, se garantiza que esto se realizará en estricto cumplimiento con la Ley Orgánica de Protección de Datos Personales.
                <br></br><br></br>
                <h3>NOTIFICACIONES Y CAMBIOS EN LA POLÍTICA</h3><br></br>
                FOCUS notificará por los canales disponibles la actualización de la presente política y sus cambios de forma previa a la entrada en vigor.
                <br></br><br></br>
                <h3>USO DE COOKIES</h3><br></br>
                FOCUS utiliza “cookies” para mejorar la navegación, calidad de los servicios web y experiencia del usuario.
            </div>
            <div style={{ height: "70px", marginTop: "150px", backgroundColor: "#F2D747" }}>
      
            </div>
        </div>
    );
};
