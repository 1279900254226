import React from "react";
// import { Link } from "react-router-dom";

/**
 * Componente para mostrar la información de la empresa
 * información desde el archivo .src/data/data.json -> "Services"
 *
 * @component
 * @example
 * 
 * "Us": [
    {
      "Text1": "asdadasd",
      "Text2": "asdadsad",
      "Text3": "asdasddd"
    }
  ]
 * 
 */

export const Us = (props) => {

  if(props.data)
  {
    return (
      <div id="us">
        <div className="container-fluid padding-fluid">
          <div className="section-title">
            <h2>Nosotros</h2>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
                <p dangerouslySetInnerHTML={{__html:props.data ? props.data.text1 : ""}}></p>
                <p dangerouslySetInnerHTML={{__html:props.data ? props.data.text2 : ""}}></p>
                <p dangerouslySetInnerHTML={{__html:props.data ? props.data.text3 : ""}}></p>
            </div>
            <div className="col-xs-12 col-md-1">
                &nbsp;
            </div>
            <div className="col-xs-12 col-md-4">
                <img alt="" className="img-responsive" src="img/us/image1.png"></img>
            </div>
            <div className="col-xs-12 col-md-1">
                &nbsp;
            </div>
          </div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div id="wyus" className="section-title">
            <h2>¿Por qué Focus?</h2>
          </div>
        </div>
      </div>
    );
  }
  else
    return (<div></div>);
};
